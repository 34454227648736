import React from "react";
import {
  Flex,
  Box,
  Text,
  useColorModeValue,
  useStyleConfig,
} from "@chakra-ui/react";
import "react-lazy-load-image-component/src/effects/blur.css";
import Navbar from "../../components/Navbar/Navbar";
import routes from "../../routes";

export default function Dashboard() {
  const styles = useStyleConfig("Card");

  let highlightTextColor = useColorModeValue("lightblue.100", "lightblue.100");
  let textColor = useColorModeValue("gray.700", "white");

  const getActiveRoute = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return routes[i].navbarDisplayName;
      }
    }
  };

  return (
    <Box>
      <Navbar displayText={getActiveRoute(routes)} />
      <Box pt={{ base: "60px", md: "80px", xl: "10px" }}>
        <Flex direction="row" justifyContent="center" alignItems="center">
          
            <Box
              pt={{ xl: "100px" }}
              pl="10px"
              textAlign="center"
              fontSize={{ sm: "1.5em", md: "3em", xl: "2.5em" }}
            >
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box __css={styles} border="none">
                  <Text
                    fontFamily="Roboto"
                    fontWeight="bold"
                    color={textColor}
                  >
                    Hola a todos!!
                  </Text>

                  <Text
                    fontFamily="Roboto"
                    fontWeight="bold"
                    color={textColor}
                    mt="20px"
                  >
                    Soy{" "}
                    <Text display="inline-flex" color={highlightTextColor}>
                      David Pacho Basterrechea
                    </Text>
                  </Text>
                  <Text
                    fontFamily="Roboto"
                    fontWeight="bold"
                    color={textColor}
                    mt="20px"
                  >
                    Un{" "}
                    <Text display="inline-flex" color={highlightTextColor}>
                      Desarrollador de Software
                    </Text>
                  </Text>
                </Box>
              </Flex>
            </Box>      
        </Flex>
      </Box>
    </Box>
  );
}

import React from "react";
import {
  Flex,
  Link,
  List,
  ListItem,
} from "@chakra-ui/react";
import { AiFillGithub } from "react-icons/ai";
import { BiLogoLinkedin } from "react-icons/bi";

export default function Footer() {
  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: "column",
        xl: "row"
      }}
      alignItems={{
        base: "center",
        xl: "start"
      }}
      justifyContent="flex-end"
      px={{ base: "30px", md: "40px" }}
      pb="30px"
      mt="20px"
    >
      <List display="flex">
        <ListItem
          me={{
            base: "20px",
            md: "40px"
          }}
          opacity="0.6"
          _hover={{ opacity: 1 }}
          fontSize="50px"
        >
          <Link href="https://github.com/DPachoGit" target="blank">
            <AiFillGithub />
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "40px"
          }}
          opacity="0.6"
          _hover={{ opacity: 1 }}
          fontSize="50px"
        >
          <Link href="https://www.linkedin.com/in/david-pacho/" target="blank">
            <BiLogoLinkedin />
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}

import { React } from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  Grid,
  useStyleConfig,
  Text,
  useColorModeValue,
  useColorMode,
  Icon,
} from "@chakra-ui/react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { RiMotorbikeLine } from "react-icons/ri";
import { MdOutlineForest } from "react-icons/md";
import { CgGames } from "react-icons/cg";
import Navbar from "../../components/Navbar/Navbar";
import routes from "../../routes";
import TechStackCard from "../../components/techStackCard/techStackCard";
import AboutMeInfo from "../../components/aboutMeInfo/aboutMeInfo";
import node from "../../assets/icons/nodejs.svg";
import mongo from "../../assets/icons/mongodb.svg";
import js from "../../assets/icons/javascript.svg";
import react from "../../assets/icons/react.svg";
import html from "../../assets/icons/html.svg";
import css from "../../assets/icons/css3.svg";
import git from "../../assets/icons/git.svg";
import three from "../../assets/icons/three.svg";
import MySQL from "../../assets/icons/mysql.svg";
import express from "../../assets/icons/express.svg";
import java from "../../assets/icons/java.svg";
import spring from "../../assets/icons/spring-boot.svg";
import GitHubCalendar from "react-github-calendar";

export default function About() {
  const styles = useStyleConfig("Card");
  const { colorMode } = useColorMode();
  const textColorPrimary = useColorModeValue("lightblue.100", "lightblue.100");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const getActiveRoute = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return routes[i].navbarDisplayName;
      }
    }
  };

  return (
    <Box>
      <Navbar displayText={getActiveRoute(routes)} />
      <Box pt={{ base: "40px", md: "80px", xl: "80px" }}>
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb="40px"
        >
          <Grid
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box __css={styles} border="none" bg="transparent">
                <Text
                  color={textColorPrimary}
                  fontSize="2em"
                  mb="40px"
                  textAlign="center"
                >
                  {"<INFORMACION GENERAL/>"}
                </Text>
                <SimpleGrid columns={{ base: 1, md: 3 }} gap="30px">
                  <AboutMeInfo
                    boxShadow={cardShadow}
                    title="Ubicación"
                    value="Etxebarri, España"
                  />
                  <AboutMeInfo
                    boxShadow={cardShadow}
                    title="Educación"
                    value="The bridge"
                  />
                  <AboutMeInfo
                    boxShadow={cardShadow}
                    title="Idiomas"
                    value="English, Español, Euskara"
                  />
                </SimpleGrid>
              </Box>
            </Flex>
          </Grid>
        </Flex>

        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb="40px"
        >
          <Text color={textColorPrimary} fontSize="2em" textAlign="center">
            {"<MIS HABILIDADES/>"}
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ base: 2, md: 2, lg: 3, xl: 4, "2xl": 4 }}
          gap="20px"
          mb="20px"
          align={{ base: "center", xl: "center" }}
          justify={{ base: "center", xl: "center" }}
        >
          <TechStackCard imagepath={react} />
          <TechStackCard imagepath={js} />
          <TechStackCard imagepath={java} />
          <TechStackCard imagepath={spring} />
          <TechStackCard imagepath={node} />
          <TechStackCard imagepath={MySQL} />
          <TechStackCard imagepath={mongo} />
          <TechStackCard imagepath={express} background="white" />
          <TechStackCard imagepath={git} />
          <TechStackCard imagepath={html} />
          <TechStackCard imagepath={css} />
          <TechStackCard imagepath={three} />
          
        </SimpleGrid>

        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb="40px"
          mt="40px"
        >
          <Text color={textColorPrimary} fontSize="2em" textAlign="center">
            {"<MI CONTRIBUCIÓN GITHUB/>"}
          </Text>
        </Flex>
        <Box display="flex" justifyContent="center" alignItems="center">
          <GitHubCalendar
            username="DPachoGit"
            colorScheme={colorMode === "light" ? "light" : "dark"}
            year="last"
            blockSize={20}
          />
        </Box>
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb="40px"
          mt="40px"
        >
          <Text color={textColorPrimary} fontSize="2em" textAlign="center">
            {"<MIS INTERESES Y HOBBIES/>"}
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ base: 1, xl: 3, "2xl": 3 }}
          gap="20px"
          mb="20px"
          align={{ base: "center", xl: "center" }}
          justify={{ base: "center", xl: "center" }}
        >
          <Box __css={styles}>
            <Box textAlign="center">
              <Icon
                as={RiMotorbikeLine}
                width="30px"
                height="30px"
                color="inherit"
              />
            </Box>
            <Text textAlign="center" fontSize="1.5em">Motos</Text>
            <Text color={textColorPrimary} textAlign="center" fontSize="1.5em" mt="5px">              
¡Me apasiona recorrer en moto y descubrir nuevos lugares! Ya sea explorando rutas desconocidas o aventurándome en caminos emocionantes, disfruto al máximo la emoción de descubrir nuevos paisajes. Viajar en moto no solo amplía mis horizontes, sino que también me brinda experiencias de vida invaluables.
            </Text>
          </Box>
          <Box __css={styles}>
            <Box textAlign="center">
              <Icon
                as={MdOutlineForest}
                width="30px"
                height="30px"
                color="inherit"
              />
            </Box>
            <Text textAlign="center" fontSize="1.5em">Ciclismo de montaña</Text>
            <Text color={textColorPrimary} textAlign="center" fontSize="1.5em" mt="5px">
Tengo una pasión profunda por el ciclismo de montaña, ¡me encanta estar en contacto con la naturaleza! Los senderos sinuosos y la adrenalina de desafiarme a mí mismo en terrenos escarpados me brindan una sensación de libertad y aventura incomparable. Ya sea explorando nuevas rutas o simplemente disfrutando del aire fresco en la montaña, encuentro una inmensa satisfacción y tranquilidad en esta actividad al aire libre.
            </Text>
          </Box>
          <Box __css={styles}>
            <Box textAlign="center">
              <Icon
                as={CgGames}
                width="30px"
                height="30px"
                color="inherit"
              />
            </Box>
            <Text textAlign="center" fontSize="1.5em">Videojuegos</Text>
            <Text color={textColorPrimary} textAlign="center" fontSize="1.5em" mt="5px">
Los videojuegos, especialmente la saga Dark Souls, son una de mis grandes pasiones. Sumergirme en su mundo desafiante y lleno de misterios me brinda una experiencia única de inmersión y desafío. Ya sea enfrentando a poderosos jefes o explorando intricados niveles, encuentro una gran satisfacción y entretenimiento en esta saga de videojuegos.
            </Text>
          </Box>
        </SimpleGrid>
      </Box>
    </Box>
  );
}

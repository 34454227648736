import React from "react";
import {
  Box,
  SimpleGrid,
  Flex
} from "@chakra-ui/react";
import routes from "../../routes";
import Navbar from "../../components/Navbar/Navbar";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import blog from "../../assets/projects/blog.jpg";
import carbon from "../../assets/projects/carbon.jpg";
import eventos from "../../assets/projects/eventos.jpg";
import gpt from "../../assets/projects/gpt.jpg";
import lotr from "../../assets/projects/lotr.jpg";
import tankgame from "../../assets/projects/tankgame.jpg";
import agua from "../../assets/projects/agua.jpg";
import rh from "../../assets/projects/rh-app.png";

export default function Projects() {
  const getActiveRoute = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return routes[i].navbarDisplayName;
      }
    }
  };

  return (
    <Box>
      <Navbar displayText={getActiveRoute(routes)} />
      <Box pt={{ base: "20px", md: "40px", xl: "40px" }}>
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb="40px"
        ></Flex>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          gap="25px"
          alignItems="center"
          justifyContent="center"
        >

          <ProjectCard
            projectImage={rh}
            projectName={"Recursos Humanos"}
            aboutProject={"Dashboard para gestionar los recursos humanos de una empresa"}
            techStack={"REACT, JS, JAVA, SPRING-BOOT, NODEJS"}
            projectLiveLink="https://rh-app.david-pacho.com/"
            projectLink="https://github.com/DPachoGit/rh-app"
          />

          <ProjectCard
            projectImage={agua}
            projectName={"Water Quality"}
            aboutProject={"Aplicacion que te deja ver al calidad del agua en tu zona"}
            techStack={"REACT, JS, HTML, CSS, NODEJS, EXPRESS, MONGODB"}
            projectLiveLink="https://agua.david-pacho.com/"
            projectLink="https://github.com/DPachoGit/Agua-app"
          />

          <ProjectCard
            projectImage={tankgame}
            projectName={"20 Segundos"}
            aboutProject={"Destruye a tus enemigos en 20 segundos usando tu tanque"}
            techStack={"REACT, JS, HTML, CSS, THREEJS"}
            projectLiveLink="https://tankgame.david-pacho.com/"
            projectLink="https://github.com/DPachoGit/Tank"
          />

          <ProjectCard
            projectImage={eventos}
            projectName={"Eventos"}
            aboutProject={"Consulta los eventos que hay en Euskadi"}
            techStack={"JS, HTML, CSS, API, NODEJS, EXPRESS, MONGODB"}
            projectLiveLink="https://eventos.david-pacho.com/"
            projectLink="https://github.com/DPachoGit/Eventos-React"
          />

          <ProjectCard          
            projectImage={lotr}
            projectName={"Lord of the trivia"}
            aboutProject={"Trivia del señor de los anillos"}
            techStack={"JS, HTML, CSS, API"}
            projectLiveLink="https://lotr.david-pacho.com/"
            projectLink="https://github.com/DPachoGit/API-LOTR"
          />

          <ProjectCard
            projectImage={gpt}
            projectName={"Programador loco"}
            aboutProject={"Pregunta al programador loco y te responderá"}
            techStack={"JS, HTML, CSS, API"}
            projectLiveLink="https://gpt.david-pacho.com/"
            projectLink="https://github.com/DPachoGit/GPT"
          />

          <ProjectCard
            projectImage={blog}
            projectName={"Blog de notas"}
            aboutProject={"Escribe notas para recordar cosas importantes"}
            techStack={"REACT, JS, HTML, CSS"}
            projectLiveLink="https://blog.david-pacho.com/"
            projectLink="https://github.com/DPachoGit/Blog-Ejemplo"
          />

          <ProjectCard
            projectImage={carbon}
            projectName={"CO2"}
            aboutProject={"Aplicacion que devuelve la cantidad de CO2 que emiten los coches en libros"}
            techStack={"JS, HTML, CSS,"}
            projectLiveLink="https://carbon.david-pacho.com/"
            projectLink="https://github.com/DPachoGit/API-CARBON"
          />        

        </SimpleGrid>
      </Box>
    </Box>
  );
}
